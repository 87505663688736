



































































































































































































































































































import userService from '@/shared/services/userService'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { IVehicle } from '@/shared/model/vehicle.model'
import { imgToBase64, downloadFileURL, debounceInput } from '@/shared/helpers'
import { VueEditor } from 'vue2-editor'

export default Vue.extend({
  name: 'ForumView',

  components: {
    VueEditor,
  },

  data() {
    return {
      rules: [
        (files: any[]) =>
          !files ||
          !files.some((file: { size: number }) => file.size > 2_097_152) ||
          'Files should be smaller than 2 MB!',
      ],
      breadcrumbItems: [] as any[],
      newTopic: undefined as any,
      activeTopic: undefined as any,
      activeTopicAnswers: [] as any[],
      topicAnswer: undefined as any,
      vehicles: [] as IVehicle[],
      selectedVehicle: '',
      debounce: undefined as number | undefined,
      total: 0,
      editAnswerDialog: false,
      editAnswer: undefined as any,
      editTopicDialog: false,
      editTopic: undefined as any,
      validNewTopic: undefined as any,
      validAnswer: undefined as any,
      validEditAnswer: undefined as any,
      validEditTopic: undefined as any,

      headers: [
        {
          text: this.$t('created'),
          value: 'createdAt',
        },
        {
          text: this.$t('last-answer'),
          value: 'updatedAt',
        },
        {
          text: this.$t('title'),
          value: 'title',
        },
        {
          text: this.$t('vehicle'),
          value: 'vehicleId',
        },
        {
          text: this.$t('answers'),
          value: 'answers',
        },
        {
          sortable: false,
          text: this.$t('actions'),
          value: 'actions',
          align: 'end',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      company: 'company',
    }),
    userIsSystemAdmin() {
      return userService.userIsSystemAdmin()
    },
  },
  async mounted() {
    this.getVehicles()
    this.getTopic()
  },
  watch: {
    $route: function () {
      this.breadcrumbItems = []
      this.activeTopic = undefined
      this.newTopic = undefined
      this.getTopic()
    },
  },
  methods: {
    async getVehicles() {
      const result = await this.axios.get('/api/vehicle')
      if (result.status === 200 && result.data) {
        this.vehicles = result.data.items
      }
    },
    async getTopic() {
      const id = this.$route.params.id
      if (id && id !== 'new') {
        const result = await this.axios.get('/api/forumTopic/' + id)
        this.activeTopic = result.data.topic
        this.activeTopicAnswers = result.data.answers
        if (this.activeTopic) {
          this.topicAnswer = { topicId: this.activeTopic._id, content: '', attachments: [] }
          this.breadcrumbItems = [
            {
              text: this.$t('forum'),
              to: '/app/forum',
              exact: true,
            },
            {
              text: `${this.activeTopic.title}`,
            },
          ]
        }
      } else if (id === 'new') {
        this.newTopic = { title: '', content: '', vehicleId: undefined, attachments: [] as any[] }
        this.breadcrumbItems = [
          {
            text: this.$t('forum'),
            to: '/app/forum',
            exact: true,
          },
          {
            text: this.$t('new-topic'),
          },
        ]
      }
    },
    async createNewTopic() {
      const attachments = []
      for (const file of this.newTopic.attachments) {
        const newBase64 = await this.fileToBase64(file)
        if (newBase64) {
          attachments.push({ data: newBase64, type: file.type, name: file.name })
        }
      }

      const result = await this.axios.post('/api/forumTopic', { data: this.newTopic, attachments: attachments })
      if (result.status === 200 && result.data._id) {
        this.$router.push('/app/forum/' + result.data._id)
      }
    },
    async postAnswer() {
      const attachments = []

      for (const file of this.topicAnswer.attachments) {
        const newBase64 = await this.fileToBase64(file)
        if (newBase64) {
          attachments.push({ data: newBase64, type: file.type, name: file.name })
        }
      }
      const result = await this.axios.post('/api/forumTopicAnswer', {
        data: this.topicAnswer,
        attachments: attachments,
      })
      this.getTopic()
    },
    async updateTopic() {
      const attachments = []

      if (this.editTopic.newAttachments) {
        for (const file of this.editTopic.newAttachments) {
          const newBase64 = await this.fileToBase64(file)
          if (newBase64) {
            attachments.push({ data: newBase64, type: file.type, name: file.name })
          }
        }
      }
      const result = await this.axios.put('/api/forumTopic/' + this.editTopic._id, {
        data: this.editTopic,
        attachments: attachments,
      })
      this.editTopic = undefined
      this.editTopicDialog = false
      this.getTopic()
    },
    async updateAnswer() {
      const attachments = []

      if (this.editAnswer.newAttachments) {
        for (const file of this.editAnswer.newAttachments) {
          const newBase64 = await this.fileToBase64(file)
          if (newBase64) {
            attachments.push({ data: newBase64, type: file.type, name: file.name })
          }
        }
      }
      const result = await this.axios.put('/api/forumTopicAnswer/' + this.editAnswer._id, {
        data: this.editAnswer,
        attachments: attachments,
      })
      this.editAnswer = undefined
      this.editAnswerDialog = false
      this.getTopic()
    },
    fileToBase64(file: File) {
      return new Promise<string>((resolve) => {
        var reader = new FileReader()
        reader.onload = () => {
          if (reader.result) {
            resolve(reader.result.toString())
          }
        }
        reader.readAsDataURL(file)
      })
    },
    async handleImageAdded(file: File, Editor: any, cursorLocation: any, resetUploader: any) {
      const resizedBase64Img = await imgToBase64(file)
      Editor.insertEmbed(cursorLocation, 'image', resizedBase64Img)
    },
    getVehicleName(vehicleId: string) {
      const vehicle = this.vehicles.find((x) => x._id === vehicleId)
      return vehicle ? vehicle.name : this.$t('name-not-found')
    },
    openEditTopicDialog() {
      this.editTopic = JSON.parse(JSON.stringify(this.activeTopic))
      this.editTopicDialog = true
    },
    openEditAnswerDialog(answer: any) {
      this.editAnswer = JSON.parse(JSON.stringify(answer))
      this.editAnswerDialog = true
    },
    download(file: any) {
      downloadFileURL('/api/file/' + file.id, file.name, '')
    },
    async deleteTopicAnswer(id: string) {
      this.$confirm(`${this.$t('dialog.are-you-sure')}`, {
        color: 'error',
        buttonTrueText: this.$t('yes').toString(),
        buttonFalseText: this.$t('no').toString(),
        title: this.$t('dialog.delete-topic-answer').toString(),
      }).then(async (res) => {
        if (res) {
          await this.axios.delete('/api/forumTopicAnswer/' + id)
          this.getTopic()
        }
      })
    },
  },
})
